table {
  width: 100%;
  border: 2px solid black;
}

 tr th {
   border: 2px solid black;
 }

 img {
   filter: drop-shadow(2px 4px 8px #141413);
 }
