table {
  width: 100%;
  border-collapse: collapse;
}

td {
  border: 2px solid black;
}

th {
  font-size: 1.1em;
  color: #756cf5;
  border: 4px solid black;
  font-weight: normal;
  background-color: rgba(35, 35, 35, 0.9);
}

img {
  filter: drop-shadow(2px 4px 8px #141413);
}

.SortIcon {
  height: 0.5em;
}
