@import url('http://fonts.cdnfonts.com/css/runescape-uf');

.NavBar {
  display: flex;
	flex-flow: row;
	justify-content: space-between;
  background-image: url("\\resources\\images\\osrs-interface-texture.jpg");
  border: 12px solid;
  border-image: url("\\resources\\images\\osrs-interface-border-texture.PNG") 45;
  min-width: 70vw;
  max-width: 80vw;
  height: 5vh;
  background-color: white;
  color: yellow;
  margin-top: 2vh;
}

.NavBarButton {
  font-family: 'RuneScape UF', sans-serif;
  cursor: url("\\resources\\cursors\\ZamorakGodsword.cur"), auto;
  border: none;
  background-color: transparent;
  outline: none;
  color: yellow;
  font-size: calc(10px + 3vmin);
  transition: 0.25s;
  -webkit-transition: 0.25s;
}
.NavBarButton:hover {
    transition: 0.25s;
	  -webkit-transition: 0.25s;
    border: none;
    color: white;
    font-size: calc(15px + 3vmin);
}

.NavBarSocial {
  border: none;
  background-color: transparent;
  outline: none;
  color: yellow;
  font-size: calc(10px + 3vmin);
  transition: 0.25s;
  -webkit-transition: 0.25s;
}

.SocialImage {
  height: 4vh;
  width: auto;
}
.SocialImage:hover {
  height: 4.05vh;
  width: auto;
}

.ButtonContainer {
  margin-left: 1vw;
  display: flex;
	flex-flow: row;
	justify-content: space-between;
}

.SocialsContainer {
  margin-top: 3px;
}
