@import url('http://fonts.cdnfonts.com/css/runescape-uf');

.App {
  cursor: url("\\resources\\cursors\\BandosGodsword.cur"), auto;
  display: flex;
  text-align: center;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  background-image: url("\\resources\\images\\background-zuk.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 200vh;
  background-color: black;
  min-height: 100vh;
  width: 100%;

}

.PageContents {
}

.App-body {
  background-image: url("\\resources\\images\\osrs-interface-texture.jpg");
  border: 12px solid;
  border-image: url("\\resources\\images\\osrs-interface-border-texture.PNG") 45;
  font-family: 'RuneScape UF', sans-serif;
  min-width: 70vw;
  max-width: 80vw;
  background-color: white;
  color: yellow;
}

h1 {
  font-weight: normal;
}

h3 {
  letter-spacing: 0.5px;
  font-weight: normal;
}

.App-link {
  color: #61dafb;
}

a {
  color: #756cf5;
  text-decoration: none;
}

a:visited {
  color: #d6114c;
}
